import React, {useEffect, useState} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Amplify, Auth, Storage} from "aws-amplify";
import { navigate } from "gatsby"
import SocialMediaCarrousel, {SOCIAL_MEDIA_POSTS_CONTEXTS} from "../components/social-media-carrousel";
import HeaderAuthenticated from "../components/header-authenticated";
import Footer from "../components/footer";
import {symbol} from "prop-types";
import {Col, Container, Row} from "react-bootstrap";
import BlockTitle from "../components/block-title";
import avatar from "../assets/images/avatar.png";
import SocialMediaPost from "../components/social-media-post";
import HorizontalSpacer from "../components/horizontal-spacer";

const getAmplifyConfig = async () => {
    let domain = "picster.ai";

    return (await fetch(`https://${domain}/amplify-config.json`)).json();
}

Amplify.configure(await getAmplifyConfig());

const Suggestions = () => {
    const [suggestions, setSuggestions] = useState([]);

    useEffect(async () => {
        try {
            const user = await Auth.currentSession();
            setSuggestions(await fetchSuggestions());
        } catch (e) {
            navigate("/");
        }
    }, []);

    const fetchSuggestions = async () => {
        const suggestionsResponse = await Storage.list("suggestions/", {level: 'private'});

        /* suggestionsResponse Structure:
            {key: "suggestions/beach/", eTag: "\"d41d8cd98f00b204e9800998ecf8427e\"", lastModified: Fri Jun 09 2023 18:08:38 GMT-0400 (Eastern Daylight Saving Time), size: 0}
            {key: "suggestions/beach/DSCN173.png", eTag: "\"e2fad9a113b7d1c0d687bced7d263e2e\"", lastModified: Fri Jun 09 2023 18:10:14 GMT-0400 (Eastern Daylight Saving Time), size: 1226191}
            {key: "suggestions/beach/DSCN183.png", eTag: "\"4fc39ef155601b8f437ea0bf9ffa6f6e\"", lastModified: Fri Jun 09 2023 18:10:14 GMT-0400 (Eastern Daylight Saving Time), size: 1139728}
         */

        /* Image Output structure:
            {
                "beach": [
                   "url"
                ]
            }
         */

        /* Caption Output structure:
            {
                "beach": [
                   "caption"
                ]
            }
         */

        /* Combined output structure:
            [
                {
                    "beach": [
                       "url"
                    ]
                },
                {
                    "beach": [
                       "caption"
                    ]
                }
            ]
         */

        let imageSuggestions = {};
        let captionSuggestions = {};

        for (const suggestionResponse of suggestionsResponse.results) {
            if (isKeyToFile(suggestionResponse.key)) { // we don't care about the root folder of suggestions, and the context folder, we need the files beneath
                if (isTextFile(suggestionResponse.key)) {
                    captionSuggestions = await appendToSuggestions(captionSuggestions, suggestionResponse.key, true);
                } else {
                    imageSuggestions = await appendToSuggestions(imageSuggestions, suggestionResponse.key, false);
                }
            }
        }

        return [imageSuggestions, captionSuggestions];
    }

    const isKeyToFile = (key) => {
        const keyParts = key.split("/");
        return (keyParts.length === 3 && keyParts[2] !== "");
    }

    const isTextFile = (key) => {
        const keyParts = key.split("/");

        let fileKeyParts = keyParts[2].split(".");

        return fileKeyParts.length === 2 && fileKeyParts[1] === 'txt';
    }

    const appendToSuggestions = async (currentSuggestions, newKey, isText) => {
        const keyParts = newKey.split("/");

        const context = keyParts[1];

        let currentContextSuggestions = currentSuggestions[context] || [];

        const newItemResponse = await Storage.get(newKey, {
            level: 'private',
            download: isText // defaults to false
        });

        let newItem = isText ? await newItemResponse.Body.text() : newItemResponse;

        currentContextSuggestions.push(newItem);

        currentSuggestions[context] = currentContextSuggestions;

        return currentSuggestions;
    }

    const getImageSuggestions = () => {
        return suggestions[0];
    }

    const getCaptionSuggestions = () => {
        return suggestions[1];
    }

    const renderCarrouselItems = () => {
        const imageSuggestions = getImageSuggestions();
        const captionSuggestions = getCaptionSuggestions();

        if (!imageSuggestions || !captionSuggestions) {
            return;
        }

        const contexts = Object.keys(imageSuggestions);

        return contexts.map((context) => {
            return <SocialMediaCarrousel key={captionSuggestions[context]} title={context} images={imageSuggestions[context]} captions={captionSuggestions[context]} />
        });
    }

    const renderLoading = () => {
        return <section className="blog-style-one" id="social-media-carrousel">
            <Container>
                <HorizontalSpacer height={150} />
                <div className='page-loading'></div>
            </Container>
        </section>
    }

    return (
        <Layout pageTitle="Suggestions">
            <Seo title="Suggestions" />
            <HeaderAuthenticated />
            {suggestions.length ? renderCarrouselItems() : renderLoading()}
            <Footer />
        </Layout>
    )
}

export default Suggestions
